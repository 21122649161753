import type { StandaloneEssentials } from './standaloneEssentials';
import type {
  Environment,
  Essentials,
  EssentialsOptions,
  EssentialsPartial,
} from '@wix/fe-essentials/core';
import {
  ChildEssentials,
  EssentialsPartialDelegate,
} from '@wix/fe-essentials/core';
import type { IHttpClient } from '../exports/http-client';

export interface RootEssentialsOptions
  extends Omit<EssentialsOptions, 'dataCapsule' | 'fedops' | 'httpClient'> {
  dataCapsule?: Partial<EssentialsOptions['dataCapsule']>;
  fedops?: Partial<EssentialsOptions['fedops']>;
  httpClient?: Omit<EssentialsOptions['httpClient'], 'artifactId'>;
  environment: {
    language: string;
    artifactId: string;
    version: string;
  };
}

export class StandaloneRootEssentials implements StandaloneEssentials {
  private readonly _delegate: EssentialsPartialDelegate;
  private readonly _environment: Environment;

  readonly asPartial: EssentialsPartial;

  constructor({
    httpClient: httpClientOptions,
    dataCapsule: dataCapsuleOptions,
    environment: environmentOptions,
    fedops: fedopsOptions,
    ...options
  }: RootEssentialsOptions) {
    this._environment = {
      ...environmentOptions,
    };

    this._delegate = new EssentialsPartialDelegate({
      ...options,
      httpClient: {
        ...httpClientOptions,
        artifactId: this._environment.artifactId,
      },
      dataCapsule: {
        namespace: this.environment.artifactId,
        ...dataCapsuleOptions,
      },
      ...(fedopsOptions && {
        fedops: {
          ...fedopsOptions,
          appName: this.environment.artifactId,
        },
      }),
      parent: {
        environment: this._environment,
      } as Essentials,
    });

    this.asPartial = {
      environment: this._environment,
      ...this._delegate,
    };
  }

  get experiments() {
    return this._delegate.getExperiments();
  }

  get fedopsLogger() {
    return this._delegate.getFedopsLogger();
  }

  get errorMonitor() {
    return this._delegate.getErrorMonitor();
  }

  get i18n() {
    return this._delegate.getI18n();
  }

  get biLoggerFactory() {
    return this._delegate.getBILoggerFactory();
  }

  get biLogger() {
    return this._delegate.getBILogger();
  }

  get httpClient(): IHttpClient {
    return this._delegate.getHttpClient();
  }

  get environment() {
    return this._environment;
  }

  get dataCapsule() {
    return this._delegate.getDataCapsule();
  }

  get createCurrencyFormatter() {
    return this._delegate.getCreateCurrencyFormatter();
  }

  get formatAddress() {
    return this._delegate.getFormatAddress();
  }

  get abTranslate() {
    return this._delegate.getAbTranslate();
  }

  get panoramaClient(): Essentials['panoramaClient'] {
    throw new Error(
      'Panorama Client is not implemented in Standalone Essentials',
    );
  }

  createChildEssentials = (
    options: Omit<EssentialsOptions, 'parent'> = {},
  ): Essentials => {
    return new ChildEssentials({
      ...options,
      parent: this,
    });
  };
}
